import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UserDataContext from "../contexts/UserDataContext";
import history from "../routes/history";
import { DEFAULT_COUNTRY, DEFAULT_TIMEZONE } from "../i18n";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

const openProfile = () => {
  history.push("/account/profile");
};

const Footer = () => {
  const VERSION = process.env.REACT_APP_VERSION;
  const userData = useContext(UserDataContext);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={4}>
            <List>
              <ListItem component="a" href="#">
                <ListItemText primary={t("Privacy")} />
              </ListItem>
              <ListItem component="a" href="#">
                <ListItemText primary={t("Terms of Service")} />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={8} justifyContent="flex-end">
          <List>
            <ListItem>
              <ListItemText
                onClick={openProfile}
                primary={`© ${new Date().getFullYear()} - Aporo Web Core v${VERSION} - ${
                  (userData && userData.langCode) || DEFAULT_COUNTRY
                } - Timezone: ${(userData && userData.timezone) || DEFAULT_TIMEZONE}`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Footer;
