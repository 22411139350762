import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import errorHandler, { init as initErrorHandling } from "./pages/utils/errorHandler";
import Page500 from "./pages/errors/Page500";

initErrorHandling();

const uncaughtErrorHandler = (error, componentStack) => {
  console.error("Error caught", error, componentStack);
  errorHandler.report(error);
};

render(
  <Sentry.ErrorBoundary fallback={Page500} onError={uncaughtErrorHandler}>
    <App />
  </Sentry.ErrorBoundary>,
  document.querySelector("#root")
);
