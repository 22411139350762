import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

function Loader() {
  return (
    <Root>
      <Box m={2}>
        <CircularProgress m={2} color="secondary" />
      </Box>
    </Root>
  );
}

export default Loader;
