import { ApolloClient, ApolloLink, from, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import * as Sentry from "@sentry/react";
import { auth0ClientPromise } from "../auth/auth0";
import cache from "./cache";

let correlationId;

/**
 * Used for logs and error reporting
 * Identifiy client and server request
 */
export const getCorrelationId = () => {
  return correlationId;
};

export const updateCorrelationId = () => {
  correlationId = Math.random().toString(36).substr(2, 9);
};

updateCorrelationId();

// helpers
const isSubscription = (query) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === "OperationDefinition" && operation === "subscription";
};

let auth0Token;
export const getAuth0Token = () => auth0Token;

const getToken = async () => {
  const authClient = await auth0ClientPromise;
  return authClient.getTokenSilently();
};

const asyncFetchAuthToken = setContext(async () => {
  const token = await getToken();
  if (process.env.NODE_ENV !== "production" && token !== auth0Token) {
    console.log("Fetched new token:");
    console.log(token);
  }
  auth0Token = token;
  return { token };
});

export const forceTokenReload = async () => {
  const authClient = await auth0ClientPromise;
  const token = await authClient.getTokenSilently({ ignoreCache: true });
  if (process.env.NODE_ENV !== "production" && token !== auth0Token) {
    console.log("Forced fetched new token:");
    console.log(token);
  }
  auth0Token = token;
  return token;
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const { query, getContext } = operation;
  // get the token from context. Should be there from asyncFetchAuthToken link
  const { token } = getContext();

  if (!isSubscription(query)) {
    // add the authorization to the headers if it's not a subscription
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
  }

  return forward(operation);
});

const apiLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_API_WS || "wss://apple-packer-web.appspot.com/graphql-ws",
      connectionParams: async () => {
        const token = await getToken();
        return { authorization: `Bearer ${token}` };
      },
    })
  ),
  new HttpLink({
    uri: process.env.REACT_APP_API_HTTP || "https://apple-packer-web.appspot.com",
  })
);

const transactionIdMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-Correlation-ID": correlationId,
    },
  }));

  return forward(operation);
});

// todo: when error routing from the client is settled on, this is the extension point to handle network and graphql errors differently
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureException(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      )
    );
  if (networkError)
    Sentry.captureException(new Error(`[Network error]: ${JSON.stringify(networkError)}`));
});

const client = new ApolloClient({
  cache,
  link: from([transactionIdMiddleware, errorLink, asyncFetchAuthToken, authMiddleware, apiLink]),
});

export default client;
