import { ApolloProvider } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import Routes from "./routes/Routes";
import client, { updateCorrelationId, getCorrelationId } from "./apollo/client";
import Auth0Provider, { auth0ClientPromise } from "./auth/auth0";
import history from "./routes/history";
import maTheme from "./theme";
import { UserDataProvider } from "./contexts/UserDataContext";

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

history.listen(() => {
  updateCorrelationId();
  Sentry.configureScope((scope) => {
    scope.setTag("correlation_id", getCorrelationId());
  });
});

// ? Maybe a better way would be Auth0Provider having a dependency on ApolloProvider and storing auth in Apollo cache?
// Taken from Auth0's updated docs https://auth0.com/docs/quickstart/spa/react/01-login
const App = () => (
  <Auth0Provider authClientPromise={auth0ClientPromise} onRedirectCallback={onRedirectCallback}>
    <ApolloProvider client={client}>
      <UserDataProvider>
        <Helmet titleTemplate="%s | Aporo Web Core" defaultTitle="Aporo Web Core" />
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={maTheme}>
            <ThemeProvider theme={maTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <Routes />
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </UserDataProvider>
    </ApolloProvider>
  </Auth0Provider>
);

export default Sentry.withProfiler(App);
