import MuiAppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Hidden from "@material-ui/core/Hidden";
import MuiIconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AccountBox as AccountIcon, ExitToApp } from "@material-ui/icons";
import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";

import { useTranslation } from "react-i18next";
import { useAuth0 } from "../auth/auth0";
import history from "../routes/history";
import PackerSearch from "./PackerSearch";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  color: ${(props) => props.theme.header.color};
  svg {
    width: 22px;
    height: 22px;
  }
`;

const BrandChip = styled(Chip)`
  background-color: ${(props) => props.theme.sidebar.header.brand.color};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 60%;
  height: 20px;
  margin-left: 2px;
  margin-bottom: 1px;
  padding: 4px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.5)}px;
    padding-right: ${(props) => props.theme.spacing(1.5)}px;
  }
`;

const MenuDesktop = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("lg")} {
    display: flex;
  }
`;

const MenuMobile = styled.div`
  display: flex;
  ${(props) => props.theme.breakpoints.up("lg")} {
    display: none;
  }
`;

const AvatarIcon = styled(Avatar)`
  width: ${({ theme }) => `${theme.spacing(6)}px`};
  height: ${({ theme }) => `${theme.spacing(6)}px`};
`;

const HeaderTitle = styled(Typography)`
  margin-right: 15px;
  display: block;
  ${(props) => props.theme.breakpoints.up("lg")} {
    display: none;
  }
`;

const Header = (props) => {
  const { onDrawerToggle } = props;

  const { logout, user } = useAuth0();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const { t } = useTranslation();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleAccountButtonClick = (event) => {
    setProfileAnchor(event.currentTarget);
  };

  const handleAccountButtonClose = () => {
    setProfileAnchor(null);
  };
  const handleLogout = () => {
    const returnTo = `${window.location.protocol}//${window.location.host}`;
    logout({ returnTo });
  };
  const openProfile = () => {
    history.push("/account/profile");
  };
  const handleDrawerToggle = () => {
    onDrawerToggle();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "account-menu";
  const renderAccountMenu = (
    <Menu
      anchorEl={profileAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(profileAnchor)}
      onClose={handleAccountButtonClose}
    >
      <MenuItem onClick={openProfile}>{t("Profile")}</MenuItem>
      <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={openProfile}>
        <IconButton color="inherit">
          <AccountIcon />
        </IconButton>
        <p>{t("Profile")}</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton color="inherit">
          <ExitToApp />
        </IconButton>
        <p>{t("Logout")}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="sticky" elevation={1}>
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <HeaderTitle variant="h6" noWrap>
            Aporo <BrandChip label="Web Core" />
          </HeaderTitle>

          <PackerSearch disabled />

          <div style={{ flexGrow: 1 }} />
          <MenuDesktop>
            <IconButton
              color="inherit"
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleAccountButtonClick}
            >
              {user && <AvatarIcon alt={user.name} src={user.picture} />}
            </IconButton>
          </MenuDesktop>
          <MenuMobile>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </MenuMobile>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderAccountMenu}
    </>
  );
};

export default withTheme(Header);
