import { grey } from "@material-ui/core/colors";

const darkVariant = {
  name: "Dark",
  palette: {
    primary: {
      main: "#039447",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#03948f",
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: "#039447",
    },
  },
  sidebar: {
    color: grey[200],
    background: "#303030",
    header: {
      color: grey[200],
      background: "#424242",
      brand: {
        color: "#039447",
      },
    },
    footer: {
      color: grey[200],
      background: "#424242",
      online: {
        background: "#039447",
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: "#FFF",
      backgroundDefault: "#039447", // Different background colors for different badge types
      backgroundNew: "#03948f",
    },
  },
  body: {
    background: "#F7F9FC",
    titleColor: grey[500],
    color: "rgba(0, 0, 0, 0.87)",
  },
};

// const variants = [
//   darkVariant
// ];

// exporting a single variant since adding themed variants is just polish

export default darkVariant;
