import React, { useEffect, Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { useAuth0 } from "../auth/auth0";
import DashboardLayout from "../layouts/Dashboard";
import GenericLayout from "../layouts/Generic";
import Page404 from "../pages/errors/Page404";
import Page403 from "../pages/errors/Page403";
import history from "./history";
import Loader from "../components/Loader";
import { dashboard as dashboardRoutes } from "./index";

// todo: This way of securing a route(s) starts to paint before the re-render triggers to perform the redirect. Look into this?
const PrivateRoute = ({ component: Component, path, adminRoute, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    // todo: Setting appState for the return URI (redirected to log in and then should return to the URI) doesn't trigger correct Route. Need to fix
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path, user]);

  let render = () => null;
  if (isAuthenticated === true && user) {
    render = (props) => <Page403 {...props} />;

    if (
      (adminRoute && user.isSuperAdmin) ||
      (!adminRoute && (user.isSuperAdmin || user.isServiceAgent))
    ) {
      render = (props) => <Component {...props} />;
    }
  }

  return <Route {...rest} path={path} render={render} />;
};

const privateChildRoutes = (Layout, routes) =>
  routes.map(({ id, children, path, component: Component, adminRoute }) =>
    children ? (
      // Route item with children
      children.map(
        ({
          id: childId,
          path: childPath,
          component: ChildComponent,
          adminRoute: childIsAdminRoute,
        }) => (
          <PrivateRoute
            key={childId}
            path={childPath}
            adminRoute={childIsAdminRoute}
            exact
            component={(props) => (
              <Layout>
                <ChildComponent {...props} />
              </Layout>
            )}
          />
        )
      )
    ) : (
      // Route item without children
      <PrivateRoute
        key={id}
        path={path}
        adminRoute={adminRoute}
        exact
        component={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        {privateChildRoutes(DashboardLayout, dashboardRoutes)},
        <Route
          render={() => (
            <GenericLayout>
              <Page404 />
            </GenericLayout>
          )}
        />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
