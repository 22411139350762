import MuiButton from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { spacing } from "@material-ui/system";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import { useAuth0 } from "../../auth/auth0";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page403() {
  const { logout } = useAuth0();
  const handleLogout = () => {
    const returnTo = `${window.location.protocol}//${window.location.host}`;
    logout({ returnTo });
  };
  return (
    <Wrapper>
      <Helmet title="403 Forbidden" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Whoops! It looks like you don&apos;t have permission to use this resource!
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        If you think this is a mistake, please contact the administrator.
      </Typography>

      <Button onClick={handleLogout} variant="contained" color="secondary" mt={2}>
        Log out
      </Button>
    </Wrapper>
  );
}

export default Page403;
