import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import moment from "moment";
import nz from "moment/locale/en-nz";
import momentTZ from "moment-timezone";

export const DEFAULT_COUNTRY = "en-nz";
export const DEFAULT_TIMEZONE = momentTZ.tz.guess();

// TODO: move into language / region select
moment.locale(DEFAULT_COUNTRY, nz);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR)
  .init({
    lng: window.localStorage.getItem("countryCode") || DEFAULT_COUNTRY,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const onTimezoneChange = (timezone) => {
  // set timezone (any formatting using moment will be on that timezone)
  momentTZ.tz.setDefault(timezone);
  window.localStorage.setItem("timezone", timezone);
};

export const onLanguageChange = (countryCode) => {
  import(`moment/locale/${countryCode || DEFAULT_COUNTRY}`).then(() => {
    moment.locale(countryCode);
  });
  i18n.changeLanguage(countryCode);
  window.localStorage.setItem("countryCode", countryCode);
};

// at initialization (before react loads) try to set default
if (window.localStorage.getItem("countryCode")) {
  onLanguageChange(window.localStorage.getItem("countryCode"));
}
export default i18n;
