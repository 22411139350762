import React from "react";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "../auth/auth0";
import { onLanguageChange, onTimezoneChange } from "../i18n";

const UserDataContext = React.createContext({
  userId: null,
  nickname: null,
  langCode: null,
  timezone: null,
});

const GET_QUERY = gql`
  query user($userId: String!) {
    user(userId: $userId) {
      userId
      nickname
      langCode
      timezone
    }
  }
`;

export const UserDataProvider = ({ children }) => {
  const { user: auth0user } = useAuth0();

  const { loading, data } = useQuery(GET_QUERY, {
    variables: {
      userId: auth0user ? auth0user.sub : null,
    },
    skip: !auth0user || !auth0user.sub,
    onCompleted: ({ user: userData }) => {
      console.log(
        `User data loaded. Set timezone ${userData.timezone} and language ${userData.langCode}`
      );
      if (userData.timezone) {
        onTimezoneChange(userData.timezone);
      }
      if (userData.langCode) {
        onLanguageChange(userData.langCode);
      }
    },
  });

  const user = !loading && data && data.user ? data.user : null;
  return <UserDataContext.Provider value={user}>{children}</UserDataContext.Provider>;
};
export const UserDataConsumer = UserDataContext.Consumer;
export default UserDataContext;

UserDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
