import { gql, useQuery } from "@apollo/client";
import MuiTextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { alpha } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import history from "../routes/history";

const PACKERS = gql`
  query packers {
    packers {
      id
      webId
      name
      packhouse {
        id
        name
      }
    }
  }
`;

const SearchWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${alpha(theme.palette.common.white, 0.15)};
    &:hover {
      background-color: ${alpha(theme.palette.common.white, 0.25)};
    }
    margin-left: 0;
    width: auto;
  `}
`;

const Adornment = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const Input = styled(MuiTextField)`
  color: inherit;

  & input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: 48px !important;
    min-width: 362px !important;

    ${(props) => props.theme.breakpoints.up("sm")} {
      &:focus {
        width: 30ch;
      }
    }
  }
`;

const PackerIdTypography = styled(Typography)`
  color: ${(props) => props.theme.body.titleColor};
  margin-left: 5px;
`;

const SearchComponent = (params) => {
  const { loading } = params;
  const { t } = useTranslation();

  return (
    <Input
      fullWidth
      size="small"
      variant="outlined"
      {...params}
      aria-label={t("Search packers")}
      placeholder={t("Search packers")}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

const onPackerSelect = (event, value) => {
  if (value && value.webId) {
    history.push(`/packers/${value.webId}`);
  }
};

const PackerSearch = () => {
  const { loading, data } = useQuery(PACKERS);
  const [packers, setPackers] = useState([]);

  useEffect(() => {
    // Sort by packhouse. Note: could be done on server side
    if (data && data.packers.length) {
      setPackers(
        data.packers.slice().sort((a, b) => {
          return a.packhouse.name.localeCompare(b.packhouse.name);
        })
      );
    }
  }, [data]);

  return (
    <SearchWrapper>
      <Adornment>
        <SearchIcon />
      </Adornment>
      <Autocomplete
        fullWidth
        id="grouped-demo"
        options={packers}
        loading={loading}
        groupBy={(packer) => packer.packhouse.name}
        getOptionLabel={(packer) => `${packer.name} ${packer.id}`}
        renderOption={(packer) => (
          <>
            {packer.name} <PackerIdTypography variant="caption">({packer.id})</PackerIdTypography>
          </>
        )}
        renderInput={(params) => <SearchComponent {...params} />}
        onChange={onPackerSelect}
      />
    </SearchWrapper>
  );
};

export default PackerSearch;
