/* eslint-disable import/no-cycle */
import { Event, Layers, ListAlt, Tune, Domain, Add, AssignmentInd } from "@material-ui/icons";
import React from "react";

const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Overview = React.lazy(() => import("../pages/overview/Overview"));
const Detail = React.lazy(() => import("../pages/PackerDetail"));
const Logs = React.lazy(() => import("../pages/logs/ViewLogs"));
const Issues = React.lazy(() => import("../pages/issues/Issues"));
const IssueDetail = React.lazy(() => import("../pages/IssueDetail"));
const Packhouses = React.lazy(() => import("../pages/admin/Packhouses"));
const Packhouse = React.lazy(() => import("../pages/admin/Packhouse"));
const EditPacker = React.lazy(() => import("../pages/admin/Packer"));
const Users = React.lazy(() => import("../pages/admin/Users"));
const User = React.lazy(() => import("../pages/admin/User"));
const Profile = React.lazy(() => import("../pages/Profile"));

export const dashboardsRoute = {
  id: "Dashboard",
  path: "/",
  icon: <Tune />,
  component: Dashboard,
};

export const overviewRoute = {
  id: "Overview",
  path: "/packers",
  icon: <Layers />,
  component: Overview,
};

export const logsRoute = {
  id: "Logs",
  path: "/logs",
  icon: <ListAlt />,
  component: Logs,
};

export const issuesRoute = {
  id: "Issues",
  path: "/issues",
  icon: <Event />,
  component: Issues,
  // badge: "new",
  // badgeType: "new",
};

export const issueDetailRoute = {
  id: "Issue Detail",
  path: "/issues/:issueId",
  name: "Issue Detail",
  component: IssueDetail,
};

// hidden route as not exported via default (not shown in sidebar)
export const detailRoute = {
  id: "Detail",
  path: "/packers/:packerId",
  name: "Detail",
  component: Detail,
};

export const addPackerRoute = {
  id: "Add Packer",
  path: "/packer",
  name: "Add Packer",
  component: EditPacker,
};

export const editPackerRoute = {
  id: "Edit Packer",
  path: "/packer/:packerId",
  name: "Edit Packer",
  component: EditPacker,
  adminRoute: true,
};

export const profileRoute = {
  id: "Profile",
  path: "/account/profile",
  name: "Profile",
  component: Profile,
};

export const packhouseRoute = {
  id: "Packhouse",
  path: "/packhouse/:packhouseId",
  name: "Detail",
  component: Packhouse,
  adminRoute: true,
};

export const packhouseAddRoute = {
  id: "Packhouse",
  path: "/packhouse",
  name: "Add",
  icon: <Add />,
  component: Packhouse,
  adminRoute: true,
};

export const packhousesRoute = {
  id: "ListPackhouses",
  name: "List",
  path: "/packhouses",
  icon: <Domain />,
  component: Packhouses,
  adminRoute: true,
};

export const packhouseCategory = {
  id: "Packhouses",
  icon: <Domain />,
  children: [packhousesRoute, packhouseAddRoute],
  adminRoute: true,
};

export const usersRoute = {
  id: "Users",
  name: "List",
  path: "/users",
  icon: <AssignmentInd />,
  component: Users,
  adminRoute: true,
};

export const userEditRoute = {
  id: "User Edit",
  path: "/user/:userId",
  name: "User Edit",
  component: User,
  adminRoute: true,
};

export const userAddRoute = {
  id: "User",
  path: "/user",
  name: "Add",
  icon: <Add />,
  component: User,
  adminRoute: true,
};

export const usersCategory = {
  id: "Users",
  icon: <AssignmentInd />,
  children: [usersRoute, userAddRoute],
  adminRoute: true,
};

export const dashboard = [
  dashboardsRoute,
  overviewRoute,
  logsRoute,
  issuesRoute,
  issueDetailRoute,
  detailRoute,
  profileRoute,
  packhousesRoute,
  packhouseRoute,
  packhouseAddRoute,
  addPackerRoute,
  editPackerRoute,
  usersRoute,
  userEditRoute,
  userAddRoute,
];

export default [dashboardsRoute, overviewRoute, logsRoute, packhouseCategory, usersCategory];
