import { InMemoryCache, makeVar } from "@apollo/client";

// log viewer variables from v3
const authTokenVar = makeVar("");
const selectedPackerVar = makeVar("");
const logOptionsVar = makeVar(null);
const logTimeRangeBlocksVar = makeVar([]);
const logCurrentTimeRangeVar = makeVar({
  start: null,
  end: null,
});
const logSelectedTimeRangeVar = makeVar({
  start: null,
  end: null,
});
const logLoadNextBlockVar = makeVar(true);
const refreshingCacheVar = makeVar(false);

/**
 * Note: the cache is not persistent. It is a "session" cache
 */
const cache = new InMemoryCache({
  typePolicies: {
    LiveStatistic: {
      keyFields: ["packerId"],
    },
  },
});

export default cache;
export {
  authTokenVar,
  selectedPackerVar,
  logOptionsVar,
  logSelectedTimeRangeVar,
  logLoadNextBlockVar,
  logTimeRangeBlocksVar,
  logCurrentTimeRangeVar,
  refreshingCacheVar,
};
